import React from 'react';
import PriceCard from "../../Molecules/PriceCard";
import {Container, Row, Col} from "../../Atoms/greeds";
import SiteSection from "../../Atoms/SiteSection/SiteSection";
import SectionHeader from "../../Molecules/SectionHeader";


const data =  [
  {
    priceName:"MINI",
    priceNameDesc:"Небольшой лендинг",
    priceNameDescTwo:"до 6ти блоков",
    priceDesc:"Бюджетный вариант для получения первых клиентов из сети интернет",
    priceAdd:[
      "Индивидуальный дизайн 6ти блоков",
      "Адаптация под мобильные устройства",
      "CMS (система управления контентом)",
      "Форма обратной связи",
      "Карта проезда, контакты",
      "Инструкция по изменению контента",
      "Хостинг и домен на 1 год",
    ],
    timeWork: "до 14 дней",
    price: "от 77 500 руб.",

  },
  {
    priceName:"PREMIUM",
    priceNameDesc:"Лендинг для бизнеса",
    priceNameDescTwo:"до 10ти блоков",
    priceDesc:"Выбор для компаний которые хотят увеличить количество продаж в действующем бизнесе.",
    priceAdd:[
      "Экспресс-анализ конкурентов",
      "Формирование уникального торгового предложения",
      "Создание прототипа",
      "Разработка индивидуального дизайна до 10ти блоков",
      "Адаптация под мобильные устройства",
      "CMS (система управления контентом)",
      "Форма обратной связи",
      "Карта проезда, контакты",
      "Интеграция с виджетом обратного звонка",
      "Инструкция по изменению контента",
      "Хостинг и домен на 1 год"
    ],
    timeWork: "до 30 дней",
    price: "от 114 800 руб.",

  },
  {
    priceName:"MAXIMUM",
    priceNameDesc:"Лендинг для бизнеса",
    priceNameDescTwo:"на максималках",
    priceDesc:"Для компаний которые хотят получить результат. Отстроиться от конкурентов. Сделать лучшее предложение на рынке.",
    priceAdd:[
      "Углубленный анализ конкурентов",
      "Формирование уникального торгового предложения",
      "Написание продающих текстов",
      "Создание прототипа",
      "Разработка индивидуального дизайна",
      "Адаптация под мобильные устройства",
      "CMS (система управления контентом)",
      "Форма обратной связи",
      "Карта проезда, контакты",
      "Интеграция с виджетом обратного звонка",
      "Квиз-форма",
      "Месяц тех-поддержки",
      "Инструкция по изменению контента",
      "Хостинг и домен на 1 год",
    ],
    timeWork: "до 70 дней",
    price: "от 147 240 руб.",

  }
];



function Price(props) {


  return (
    <SiteSection>
      <Container>
        <SectionHeader
          h2
          bigText="Вознаграждение"
        >Цена разработки лендинга</SectionHeader>
        <Row>
          {data.map( (i,k) => <Col lg={4} md={6}><PriceCard accent={k == 1 ? true : false} item={i}/></Col>)}
        </Row>
      </Container>

    </SiteSection>
  );
}

export default Price;