import React from 'react';
import * as styles from './DotedText.module.scss'


function DotedText(props) {
    let {children, className} = props;

    return (
        <div className={`${styles.wrap} ${className}`}>
            <span className={styles.dot}></span>
            <p className={styles.text}>{children}</p>
        </div>
    );
}

export default DotedText;