import React from 'react';
import SiteSection from "../../Atoms/SiteSection/SiteSection";
import {Col, Container, Row} from "../../Atoms/greeds";
import SectionHeader from "../../Molecules/SectionHeader";
import CardTwo from "../../Molecules/CardTwo";
import * as styles from './HowToLanding.module.scss'

const data = [
  {
    header:"Разработка стратегии",
    desc:"Изучаем продукт, рынок, спрос, аудиторию. Готовим стратегию интернет-маркетинга",
    img:"../../imgs/Strata.svg",
  },
  {
    header:"Изучаем пути клиентов",
    desc:"Ищем пути клиентов на сайт, для каждой стадии подбираем поисковые запросы.",
    img:"../../imgs/CJM.svg",
  },
  {
    header:"Формируем УТП",
    desc:"Прорабатываем целевые аудитории для подготовки УТП под каждый сегмент.",
    img:"../../imgs/utp.svg",
  },
  {
    header:"Проектируем интерфейс",
    desc:"Разрабатываем текстовый прототип. Его собирают маркетологи, а не копирайтеры!",
    img:"../../imgs/Interfaces.svg",
  },
  {
    header:"Дизайн сайта",
    desc:"Так как мы тщательно проработали прототип, макеты рисуются быстрее чем в классической веб-студии",
    img:"../../imgs/Design.svg",
  },
  {
    header:"Верстка и программирование",
    desc:"Собственный отдел разработки из 10 человек гарантирует качество кода и скорость работы сайта.",
    img:"../../imgs/develop.svg",
  },

]

function HowToLanding(props) {
  return (
    <SiteSection className={styles.howToLanding}>
      <Container>
        <SectionHeader
          h2
          bigText="Как мы создаем лендиги"
        >Этапы работы</SectionHeader>
        <Row>
          {data.map((i,k) => {
            return <Col lg={6}><CardTwo card={i} key={k} index={k} className={styles.card}/></Col>
          })}
        </Row>
      </Container>
    </SiteSection>
  );
}

export default HowToLanding;