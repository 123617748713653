// extracted by mini-css-extract-plugin
export var card = "PriceCars-module--card--3TDeU";
export var line = "PriceCars-module--line--1YYWa";
export var accentName = "PriceCars-module--accentName--348bm";
export var header = "PriceCars-module--header--N_CAu";
export var afterHeader = "PriceCars-module--afterHeader--2kohO";
export var desc = "PriceCars-module--desc--h_yPY";
export var badge = "PriceCars-module--badge--2QGSW";
export var listIncludes = "PriceCars-module--listIncludes--1ZycE";
export var active = "PriceCars-module--active--_tbj-";
export var dotedText = "PriceCars-module--dotedText--3NK3o";
export var time = "PriceCars-module--time--qgKB3";
export var price = "PriceCars-module--price--1DW3e";
export var btn = "PriceCars-module--btn--3T2An";
export var accent = "PriceCars-module--accent--RjuUD";