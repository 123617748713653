import React from 'react';
import "../../scss/main.scss"
import Header from '../../components/header';
import Main from '../../components/main';
import PageHeader from '../../components/Organism/PageHeader/PageHeader';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer/Footer';
import StartWork from '../../components/forms/StartWork';


import Price from "../../components/Organism/price/price";
import Benefits from "../../components/Organism/benefits/benefits";
import HowToLanding from "../../components/Organism/howtoLanding/HowToLanding";
import Testimonials from "../../components/Organism/testimonials/testimonials";
import Portfolio from "../../components/Organism/Portfolio/Portfolio";
import Faq from "../../components/Organism/Faq/Faq";
import Cta from "../../components/Organism/Cta/Cta";

function LandingsPage(props) {
    const form = <StartWork/>;
    return (
        <>
            <Helmet
                title="Разработка Landing Page с проработкой под вашу целевую аудиторию"
                meta={
                [
                    {
                    name:"description",
                    content:"Закажи разработку лендинга по отличной цене! Агентство интернет маркетинга с комплексной заботой к вашему бизнесу"
                    }
                ]
                }
            /> 
            <Header/>
            <Main>
                <PageHeader h1={"Разработка Лендингов"}
                    p={`Создаем высоко конверсионные посадочные страницы через аналитику вашего бизнеса. Если лендинг у вас уже есть, поможем его улучшить как с визуальной точки зрения, так и с точки зрения маркетинга`} modalButton modalContent={form}/>

                <Benefits/>
                <Price/>
                <Cta/>
                <HowToLanding/>
                <Portfolio/>
                <Faq/>
            </Main>
            <Footer/>
        </>
    );
}

export default LandingsPage;