import React, {useState} from 'react';

import {Badge} from "../../Atoms/Badge";
import DotedText from "../../Atoms/DotedText";
import {Button} from "../../Atoms/Buttons/Button";
import * as styles from './PriceCars.module.scss'
import Modal from "../Modal/Modal";
import StartWork from "../forms/StartWork";



function PriceCard({item, accent}) {

    const [addons, setOpenAddons] = useState(false);
    const handleOpenAddons = () => {
      !addons && setOpenAddons(true);
      addons && setOpenAddons(false);
    }
    return (
        <div className={`${styles.card} ${accent && styles.accent}`}>
            <div className={styles.line}></div>
            <div className={styles.accentName}>{item.priceName}</div>
            <h3 className={styles.header}>{item.priceNameDesc}</h3>
            <p className={styles.afterHeader}>{item.priceNameDescTwo}</p>
            <p className={styles.desc}>{item.priceDesc}</p>
            <Badge className={styles.badge}>Что входит:</Badge>
            <div className={`${styles.listIncludes} ${addons && styles.active}`} onClick={handleOpenAddons}>
              {item.priceAdd.map((item) => <DotedText className={styles.dotedText}>{item}</DotedText> )}
            </div>
            <p className={styles.time}>Срок разработки {item.timeWork}</p>
            <p className={styles.price}>{item.price}</p>
            <Modal btnText="Заказать" primary={accent ? true : false} third={!accent ? true : false} modalHeader="Давайте начнем сотрудничество" className={styles.btn}>
              <StartWork/>
            </Modal>

        </div>
    );
}

export default PriceCard;